.Container {
    position: relative;
    width: 30%;
    margin: 0 16px;
}

.PopupContainer {
    position: absolute;
    left: 1px;
    top: 30px;
    z-index: 2;
    min-width: calc(100% - 2px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    filter: drop-shadow(0 24px 32px rgb(0 0 0 / 4%)) drop-shadow(0 16px 24px rgb(0 0 0 / 4%)) drop-shadow(0 4px 8px rgb(0 0 0 / 4%)) drop-shadow(0 0 1px rgb(0 0 0 / 4%));
}